try {
  const cursor = document.querySelector('div span#cursor')
  const d = document.querySelector('div span#d')
  const words = [
    'frontend/js',
    'frontend/js/vanilla',
    'frontend/js/vanilla/es6',
    'frontend/js/angularjs',
    'frontend/js/jquery',
    'frontend/js/build/webpack',
    'frontend/js/build/gulp',
    'frontend/js/build/parcel',
    'frontend/js/build/npm',
    'frontend/js/build/grunt',
    'frontend/js/build/bower ☠️',
    'frontend/js/coffeescript ☠️',
    'frontend/css',
    'frontend/css/responsive',
    'frontend/css/SCSS',
    'frontend/css/postcss',
    'frontend/css/tailwind',
    'frontend/css/bootstrap',
    'frontend/html',
    'frontend/html/hugo',
    'backend/js',
    'backend/js/express',
    'backend/rails',
    'backend/php',
    'backend/php/cakePHP',
    'backend/db/SQL',
    'AWS',
    'AWS/lambda',
    'AWS/cloudfront',
    'AWS/ec2',
    'AWS/s3',
    '.gitconfig',
    '.ssh/config',
    '.vimrc',
    '.tmux.conf',
    '.zshrc',
    '.aws',
    '.aws/config',
    '.aws/credentials',
  ]
  let k = 0,
    wordIndex = 0,
    grow = 1,
    rand = 200

  typeit()
  function typeit() {
    rand = 30 + Math.round(Math.random() * 100)
    const html = d.innerHTML
    if (grow) {
      if (html == words[k]) {
        grow = 0
      } else {
        if (!words[k][wordIndex]) grow = 0
        else {
          d.innerHTML += words[k][wordIndex]
          wordIndex++
        }
      }
      if (d.innerHTML.length == words[k].length) rand = 500
    } else {
      const trimmed = html.replace(/.$/, '')

      if (html.length) {
        const next = k == words.length - 1 ? words[0] : words[k + 1]
        if (html && next.indexOf(html) > -1) {
          k = words.indexOf(next)
          grow = 1
        } else if (trimmed.length && next.indexOf(trimmed) > -1) {
          k = words.indexOf(next)
          grow = 1
          d.innerHTML = trimmed
          wordIndex--
        } else {
          d.innerHTML = trimmed
          wordIndex--
        }
      } else {
        k = k == words.length - 1 ? 0 : k + 1
        grow = 1
        wordIndex = 0
      }
    }
    setTimeout(typeit, rand)
  }

  setInterval(
    () => (cursor.innerHTML = cursor.innerHTML.length ? '' : '_'),
    300
  )
} catch (e) {}
